.profile-claim-card {
  @extend %card;

  display: flex;
  flex-direction: column;
  border: 1px solid #e9e9e9;
  padding: {
    left: 35px;
    right: 35px;
    top: 30px;
    bottom: 30px;
  }

  &.\--primary {
    .profile-claim-card__claim-button {
      @extend %button;

      border: none;
      font-weight: 600;
      padding: {
        bottom: 10px;
        top: 10px;
      }
    }
  }

  &__top {
    @include media("min", 723px) {
      grid-template-columns: 1fr 180px;
      column-gap: 20px;
      text-align: left;
    }
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    text-align: center;
  }

  &__header {
    @include media("min", 723px) {
      grid-template-columns: 80px 1fr;
      column-gap: 20px;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  &__image {
    picture {
      display: inline-block;
      font-size: 0;

      img {
        width: 100% !important;
        max-width: 80px;
        height: auto;
        border-radius: 50%;
      }
    }
  }

  &__brokerage,
  &__location {
    font: {
      family: "Inter", sans-serif;
      size: 14px;
      weight: 400;
    }
    line-height: 16.94px;
    color: rgba(map-get($map: $colors, $key: text-black), 0.8);
    margin-bottom: 8px;
  }

  &__location {
    margin-top: 8px;
  }

  &__name {
    @extend %h3;
    word-break: break-word;
  }

  &__claim-button {
    @extend %button--secondary--outline;
    height: auto;
  }
}
