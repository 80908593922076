.MessageAgentForm {
  $text-color: rgba(39, 39, 39, 0.8);
  $defualt-animation-time: 200ms;
  $defualt-animation-ease: ease-in-out;

  @include media("min", 768px) {
    overflow-x: hidden;
  }
  @include media("min", 485px) {
    font-size: 16px;
    line-height: 19px;
  }
  color: $text-color;
  display: flex;
  flex-direction: column;
  font: {
    family: "Inter", sans-serif;
    size: 14px;
    style: normal;
    weight: normal;
  }
  height: 100%;
  line-height: 16px;

  &.\--overflow {
    @include media("min", 768px) {
      overflow-x: initial;
    }
  }

  strong {
    font-weight: 700;
  }
  &__contact-subline {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;

    &.\--agent-page {
      margin-bottom: 10px;
    }
  }

  &__agent-header {
    @include media("min", 768px) {
      grid-template-columns: max-content 1fr 1fr;
      padding: {
        bottom: 20px;
        left: 35px;
        right: 35px;
        top: 28px;
      }
    }
    @include media("min", 485px) {
      background-color: rgba(251, 251, 251, 1);
      border: {
        top: {
          left-radius: 5px;
          right-radius: 5px;
        }
      }
    }
    position: relative;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 1fr;
    padding: {
      bottom: 16px;
      left: 20px;
      right: 20px;
      top: 24px;
    }
    width: 100%;

    &.\--prime {
      @include media("min", 768px) {
        grid-template-columns: max-content max-content 1fr 4fr;
      }
      background-color: rgb(30, 41, 59);

      .MessageAgentForm__agent-name {
        color: rgba(255, 255, 255, 1);
      }

      .MessageAgentForm__agent-brokerage {
        color: rgba(255, 255, 255, 0.8);
      }

      .MessageAgentForm__agent-review-label {
        color: rgba(255, 255, 255, 0.8);
      }

      .MessageAgentForm__agent-profile-picture {
        border: 2px solid rgba(211, 161, 59, 1);
      }

      .MessageAgentForm__agent-responds,
      .MessageAgentForm__agent-hours {
        color: rgba(255, 255, 255, 0.8);

        em {
          color: rgb(4, 162, 130);
        }

        &:before {
          background-color: rgb(4, 162, 130);
        }

        &.\--closed {
          em {
            color: rgb(235, 19, 19);
          }
          &:before {
            background-color: rgb(235, 19, 19);
          }
        }
      }
    }
  }

  &.\--funnel {
    .MessageAgentForm__screen {
      @include media("min", 768px) {
        padding-top: 30px;
      }
      padding-top: 50px;
    }
  }

  &__agent-prime-badge {
    position: relative;
    display: inline-flex;
    width: 105px;
    justify-content: center;
    align-items: center;
    height: 25px;
    font: {
      style: normal;
      weight: 500;
      size: 9px;
    }
    line-height: 11px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(
      270deg,
      rgba(255, 209, 92, 1) -12.76%,
      rgba(181, 130, 28, 1) 92.47%
    );
    border-radius: 52px;
    padding: {
      left: 32px;
      right: 11px;
    }

    &::before {
      position: absolute;
      content: "";
      background: {
        image: url("/static/prime-agent-icon.png");
        repeat: no-repeat;
        position: center;
      }
      height: 17px;
      width: 17px;
      left: 11px;
    }
  }

  &__agent-prime-badge-container {
    @include media("min", 768px) {
      justify-content: flex-start;
      width: min-content;
    }
    display: flex;
    justify-content: center;
  }

  &__agent-profile-picture {
    @include media("min", 768px) {
      margin: 0;
    }
    margin: 0 auto;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__agent-information {
    @include media("min", 768px) {
      align-items: flex-start;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__agent-name {
    @include media("min", 768px) {
      order: 0;
    }
    font: {
      weight: 600;
      size: 18px;
    }
    line-height: 22px;
    color: rgba(36, 31, 58, 1);
    order: 2;
  }

  &__agent-brokerage {
    @include media("min", 768px) {
      display: block;
      order: 0;
      margin: {
        top: 4px;
        bottom: 6px;
      }
    }

    display: none;
    font-size: 14px;
    line-height: 17px;
    color: rgba(36, 31, 58, 0.8);
    order: 1;
    margin: {
      top: 8px;
      bottom: 8px;
    }
  }

  &__agent-rating {
    @include media("min", 768px) {
      order: 0;
      margin: {
        top: 0;
        bottom: 0;
      }
    }

    order: 3;
    text-align: center;
    margin: {
      top: 5px;
      bottom: 8px;
    }
  }

  &__unclaimed-agent-title {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 36px;
    text-align: center;
  }

  &__agent-review-label {
    font-size: 12px;
    line-height: 15px;
    color: rgba(36, 31, 58, 0.8);
    padding-left: 8px;
  }

  &__agent-meta-container {
    @include media("min", 768px) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: auto;
    }
  }

  &__agent-meta {
    @include media("min", 768px) {
      justify-content: flex-end;
      margin-top: auto;
      align-items: flex-start;
    }
    align: {
      items: center;
      self: flex-start;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__agent-responds,
  &__agent-hours {
    position: relative;
    display: block;
    color: rgba($text-color, 1);
    font-size: 14px;
    line-height: 24px;
    text-align: right;

    em {
      font-style: normal;
      color: rgba(22, 129, 99, 1);
    }
  }

  &__agent-responds {
    &:before {
      display: inline-block;
      position: relative;
      content: "";
      height: 15px;
      width: 15px;
      mask: {
        image: url("/static/responds-icon.svg");
        position: center;
        repeat: no-repeat;
      }
      background-color: rgba(22, 129, 99, 1);
      left: 0;
      top: 0;
      bottom: 0;
      margin-right: 6px;
    }
  }

  &__agent-hours {
    &:before {
      background: rgb(4, 120, 87) none repeat scroll 0% 0%;
      border-radius: 100%;
      bottom: 0;
      content: "";
      display: inline-block;
      height: 10px;
      left: 0;
      margin-right: 11px;
      position: relative;
      top: 0;
      width: 10px;
    }
  }

  &__progress-heading {
    max-width: calc(100% - 24px);
    color: rgba($text-color, 1);
    font: {
      weight: 600;
      size: 18px;
    }
    line-height: 22px;
    padding: {
      top: 30px;
      bottom: 30px;
      left: 35px;
      right: 35px;
    }
  }

  &__progress-header {
    @include animation-keyframes {
      from {
        opacity: 0;
        transform: translateY(-49px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    animation: {
      timing-function: $defualt-animation-ease;
      duration: $defualt-animation-time;
      fill-mode: forwards;
    }
    z-index: 3;
    background-color: rgba(248, 248, 248), 1;
  }

  &__close {
    position: absolute;
    background: {
      image: url("/static/close-icon.svg");
      repeat: no-repeat;
      position: center;
    }
    text-indent: -10000px;
    overflow: hidden;
    top: 25px;
    right: 12px;
    height: 14px;
    width: 15px;

    &.\--alt {
      background-image: url("/static/close-icon-w.svg");
      top: 15px;
      right: 15px;
    }
  }

  &__screen {
    @include media("min", 485px) {
      padding: {
        left: 35px;
        right: 35px;
        top: 40px;
      }
    }

    @include media("min", 768px) {
      min-height: 520px;
    }

    animation: {
      duration: $defualt-animation-time;
      name: fadeInRight;
      fill-mode: both;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: {
      bottom: 30px;
      left: 20px;
      right: 20px;
      top: 30px;
    }

    &.\--alt {
      animation: none;
      padding-top: 16px;
    }

    &.\--no-height {
      min-height: 290px;
    }

    &.\--missed-connection {
      animation: {
        duration: $defualt-animation-time;
        name: fadeInLeft;
      }
      padding-top: 30px;
    }

    &.\--delay {
      animation-delay: $defualt-animation-time;
    }

    &.\--alt-animation {
      animation-name: fadeIn;
    }
  }

  &__screen-heading {
    @include media("min", 485px) {
      font-size: 24px;
      line-height: 29px;
    }
    color: rgba($text-color, 1);
    font: {
      size: 18px;
      weight: bold;
    }
    line-height: 22px;
    margin-bottom: 15px;

    &.\--alt {
      margin: {
        bottom: 0;
        top: 15px;
      }
      text-align: center;
    }

    &.\--narrow {
      margin: {
        bottom: 15px;
        left: auto;
        right: auto;
        top: 0;
      }
      max-width: 403px;
    }
  }

  &__screen-text {
    margin-bottom: 30px;

    a {
      color: map-get($map: $colors, $key: rea-orange);
      font-weight: bold;
    }

    &.\--alt {
      margin-bottom: 0;
      text-align: center;
    }
  }

  &__screen-list {
    @include media("min", 485px) {
      margin: {
        left: 35px;
        right: 35px;
        top: 35px;
      }
      font-style: 14px;
      line-height: 17px;
    }
    border-top: 1px solid rgba(233, 233, 233, 1);
    color: rgba($text-color, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: 12px;
    line-height: 17px;
    margin-top: 25px;
    padding: {
      top: 30px;
      left: 20px;
      right: 20px;
    }

    li {
      list-style-image: url("/static/checkmark-bullet.svg");
      position: relative;

      & + li {
        padding-top: 10px;
      }
    }
  }

  &__screen-controls {
    @include media("max", 767px) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media("min", 768px) {
      margin-top: auto;
      padding-top: 60px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: {
      top: 30px;
    }

    &.\--back {
      padding-top: 0px;
      margin-top: 24px;
    }

    &.\--alt {
      @include media("min", 485px) {
        margin-top: 25px;
      }
      justify-content: center;
      margin-top: 20px;
    }

    button {
      @include media("min", 485px) {
        min-width: 150px;
      }
      align-items: center;
      background: rgba(234, 88, 12, 1);
      border-radius: 7px;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      display: inline-flex;
      font: {
        family: "Mulish", sans-serif;
        size: 16px;
        weight: bold;
      }
      justify-content: center;
      line-height: 20px;
      min-width: fit-content;
      padding: {
        top: 14px;
        bottom: 14px;
        left: 22px;
        right: 22px;
      }
      text-align: center;
      transition: background-color $defualt-animation-time
        $defualt-animation-ease;

      .svg-inline--fa {
        margin-right: 13px;
      }

      &.\--alt {
        @include media("min", 485px) {
          min-width: 100px;
        }
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        border: 1px solid rgba(234, 234, 234, 1);
        color: rgba(30, 41, 59, 1);

        &:hover {
          background-color: rgba(255, 255, 255, 1);
        }
      }

      &:disabled {
        background-color: rgba(234, 234, 234, 1);
        color: rgba(30, 41, 59, 1);
        cursor: not-allowed;

        &:hover {
          background-color: rgba(234, 234, 234, 1);
        }
      }

      &:hover {
        background-color: rgba(240, 137, 84, 1);
      }

      & + button {
        @include media("min", 485px) {
          margin-left: 15px;
        }
        margin-left: 10px;
      }
    }
  }

  &__clientType {
    @include media("min", 768px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 15px;
      margin-bottom: 40px;
    }
    @include media("min", 843px) {
      grid-template-columns: repeat(3, 1fr);
    }
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1fr;
    row-gap: 10px;
    margin-top: 30px;
    justify-content: center;

    span {
      &.\--inner {
        @include media("min", 768px) {
          display: inline;
        }
        display: flex;
        align-items: center;
      }
    }

    label {
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 16px;
      color: rgba(39, 39, 39, 0.6);
      cursor: pointer;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 2px;
        background: rgba(255, 255, 255, 1);
        padding: {
          top: 14px;
          bottom: 14px;
          left: 20px;
          right: 20px;
        }
      }

      svg {
        @include media("min", 768px) {
          display: block;
        }
        display: inline-block;
        margin-right: 15px;
        transition: fill 0.1s $defualt-animation-ease,
          stroke 0.1s $defualt-animation-ease;
      }

      em {
        @include media("min", 768px) {
          display: block;
        }
        display: inline-block;
        font: {
          weight: bold;
          size: 16px;
          style: normal;
        }
        line-height: 19px;
        color: rgba($text-color, 1);
        margin: {
          bottom: 10px;
          top: 10px;
        }
      }

      &:hover {
        div {
          svg {
            * {
              stroke: rgba(234, 88, 12, 1);
              fill: rgba(234, 88, 12, 1);
            }
          }
        }
      }

      input[type="radio"]:checked ~ div {
        border: 1px solid rgba(234, 88, 12, 1);
        svg {
          * {
            stroke: rgba(234, 88, 12, 1);
            fill: rgba(234, 88, 12, 1);
          }
        }
      }

      input[type="radio"]:not(:checked) ~ div {
        @include btn-border-slide(
          rgba(234, 234, 234, 1),
          rgba(234, 88, 12, 1),
          2px,
          0.3s
        );
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 2px;
        background: rgba(255, 255, 255, 1);
        padding: {
          top: 14px;
          bottom: 14px;
          left: 20px;
          right: 20px;
        }
      }
    }
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  &__clientType-subtext {
    @include media("min", 768px) {
      display: inline;
    }
    display: none;
  }

  &__screen-grid {
    @include media("min", 591px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 15px;
      row-gap: 15px;
    }
    @include media("min", 851px) {
      grid-template-columns: repeat(4, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 48px;
    column-gap: 10px;
    row-gap: 10px;

    &.\--alt {
      grid-template-columns: repeat(auto-fit, 100px);
    }

    label {
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 16px;
      color: rgba(39, 39, 39, 1);
      cursor: pointer;

      &:hover {
        div {
          border-color: rgba(234, 88, 12, 1);
        }

        svg {
          * {
            stroke: rgba(234, 88, 12, 1);
            fill: rgba(234, 88, 12, 1);
          }
        }
      }

      input[type="radio"]:checked ~ div {
        border: 2px solid rgba(234, 88, 12, 1);
        svg {
          * {
            stroke: rgba(234, 88, 12, 1);
            fill: rgba(234, 88, 12, 1);
          }
        }
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(148, 163, 184);
      border-radius: 5px;
      height: 100%;
      width: 100%;
      transition: border-color 0.1s $defualt-animation-ease;

      svg {
        display: inline-block;
        margin-right: 11px;
        transition: fill 0.1s $defualt-animation-ease,
          stroke 0.1s $defualt-animation-ease;
      }
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  &__textarea {
    position: relative;

    textarea {
      width: 100%;
      height: 150px;
      resize: none;
      background: rgba(248, 248, 248, 1);
      border: 1px solid rgba(234, 234, 234, 1);
      border-radius: 5px;
      outline: none;
      padding: {
        left: 20px;
        top: 22px;
        bottom: 15px;
        right: 20px;
      }
      transition: border-color $defualt-animation-time $defualt-animation-ease,
        border-width $defualt-animation-time $defualt-animation-ease;

      &:valid,
      &.\--valid {
        border-color: rgba(234, 234, 234, 1);
        border-width: 1px;
      }

      &:hover.\--invalid {
        border-width: 2px;
        border-color: rgba(163, 38, 0, 1);
      }

      &.\--invalid {
        border-color: rgba(163, 38, 0, 1) !important;
      }

      &:hover,
      &.\--active {
        border-color: rgba(15, 119, 74, 1);
        border-width: 2px;
      }

      &:focus,
      &.\--active-label {
        & + label {
          font-size: 12px;
          line-height: 12px;
          top: 6px;
        }
      }
    }
  }

  &__textarea-help-text {
    padding: {
      top: 8px;
      left: 20px;
    }
    list-style: none;
  }

  &__contact-info {
    @include media("min", 627px) {
      grid-template-columns: 1fr 1fr;
    }

    @include media("max", 767px) {
      display: flex;
      flex-direction: column;
    }
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    column-gap: 15px;
    row-gap: 20px;

    .\--full-width {
      grid-column: 1 / span 2;
    }
  }

  &__screen-control-text-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &.\--client-info {
      margin-top: 14px;
    }
  }

  &__get-agents-button {
    width: 100%;
  }

  &__screen-control-text {
    @include media("min", 771px) {
      display: block;
    }
    display: none;
    font-size: 12px;
    line-height: 17px;
    color: rgba(39, 39, 39, 0.8);
    padding-left: 10px;
    padding-right: 10px;

    a {
      text-decoration: underline;
    }

    &.\--client-info {
      @include media("min", 771px) {
        display: block;
      }
      padding: 0;
      display: block;
      opacity: 0.7;

      a {
        color: #0000ee;
      }
    }

    &.\--mobile {
      @include media("min", 771px) {
        display: none;
      }
      display: block;
      text-align: center;
      margin-top: 20px;
    }

    &.\--text-right {
      text-align: right;
    }

    &.\--under {
      display: block;
      text-align: left;
      margin-top: 20px;
      opacity: 0.4;

      a {
        color: #0000ee;
      }
    }
  }

  &__complete {
    padding: {
      top: 30px;
      bottom: 30px;
      left: 35px;
      right: 35px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__complete-heading {
    @include media("min", 376px) {
      font-size: 24px;
      line-height: 29px;
    }
    font: {
      weight: bold;
      size: 18px;
    }
    line-height: 22px;
    text-align: center;
    margin-bottom: 30px;
    color: rgba($text-color, 1);
  }

  &__complete-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(39, 39, 39, 0.1);
    margin-bottom: 30px;
  }

  &__complete-sub-heading {
    font: {
      weight: bold;
      size: 16px;
    }
    line-height: 19px;
    text-align: center;
    margin-bottom: 20px;
    color: rgba($text-color, 1);
  }

  &__complete-list {
    @include media("min", 485px) {
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 20px;
    }
    font-size: 14px;
    line-height: 18px;
    color: rgba($text-color, 1);
    display: flex;
    flex-direction: column;
    padding-left: 0;

    &.\--alt {
      > li {
        @include media("min", 337px) {
          flex-direction: row;
        }
        counter-increment: customlistcounter;
        position: relative;
        list-style: none;
        display: inline-flex;
        align-items: center;
        flex-direction: column;

        &:before {
          @include media("min", 337px) {
            align-self: flex-start;
            margin-bottom: 0;
          }
          display: inline-flex;
          content: "";
          min-width: 24px;
          min-height: 24px;
          background: none;
          border: 0;
          background-image: url("/static/checkmark-bullet.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin-right: 8px;
          margin-bottom: 10px;
        }

        &:first-child {
          list-style-image: none;
          margin: 0;

          &:before {
            content: "";
          }
        }
      }
    }

    > li {
      counter-increment: customlistcounter;
      position: relative;
      list-style: none;

      &:before {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        content: counter(customlistcounter) " ";
        font: {
          weight: 600;
          size: 12px;
        }
        line-height: 12px;
        width: 18px;
        height: 18px;
        color: rgba(39, 39, 39, 0.8);
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 1)
        );
        border: 1px solid rgba(234, 234, 234, 1);
        border-radius: 50%;
        margin-right: 8px;
      }

      &:first-child {
        counter-reset: customlistcounter;
        list-style: none;
        list-style-image: url("/static/checkmark-bullet.svg");
        padding-inline-start: 8px;
        margin: {
          right: 0;
          left: 18px;
        }
        &:before {
          content: none;
        }
      }
    }

    li + li {
      padding-top: 10px;
    }
  }

  &__address {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    &.\--unit {
      @include media("min", 768px) {
        grid-template-columns: 1fr 169px;
      }
    }
  }

  &__address-unit {
    position: relative;

    label {
      position: absolute;
      font-size: 16px;
      line-height: 20px;
      color: rgba(39, 39, 39, 0.6);
      left: 20px;
      top: 15px;
      transition: font-size 0.1s $defualt-animation-ease,
        line-height 0.1s $defualt-animation-ease,
        top 0.1s $defualt-animation-ease;
      pointer-events: none;
    }
    &.\--active {
      label {
        font-size: 12tpx;
        line-height: 12px;
        top: 6px;
      }
    }

    input {
      width: 100%;
      background: rgba(248, 248, 248, 1);
      border: 1px solid rgba(234, 234, 234, 1);
      border-radius: 5px;
      height: 50px;
      font: {
        family: "Inter", sans-serif;
        style: normal;
        weight: normal;
        size: 16px;
      }
      line-height: 20px;
      color: rgba($text-color, 1);
      padding: {
        left: 20px;
        right: 20px;
        top: 22px;
        bottom: 8px;
      }
      outline: none;

      &:focus {
        + label {
          font-size: 10px;
          line-height: 12px;
          top: 6px;
        }
      }
    }
  }

  &__address-input {
    position: relative;

    label {
      position: absolute;
      font-size: 16px;
      line-height: 20px;
      color: rgba(39, 39, 39, 0.6);
      left: 20px;
      top: 15px;
      transition: font-size 0.1s $defualt-animation-ease,
        line-height 0.1s $defualt-animation-ease,
        top 0.1s $defualt-animation-ease;
      pointer-events: none;
      z-index: 2;
    }
    &.\--active {
      label {
        font-size: 12px;
        line-height: 12px;
        top: 6px;
      }

      input {
        border-color: rgba(15, 119, 74, 1) !important;
      }
    }

    &.\--valid {
      input {
        border-color: rgba(15, 119, 74, 1) !important;
        box-shadow: rgba(15, 119, 74, 1) 0px 0px 0px 1px inset;
      }
    }

    &.\--invalid {
      input {
        &:hover {
          box-shadow: rgba(163, 38, 0, 1) 0px 0px 0px 1px inset !important;
          border-color: rgba(163, 38, 0, 1) !important;
        }
      }
    }

    &.\--invalid {
      input {
        box-shadow: none !important;
        border-color: rgba(163, 38, 0, 1) !important;
      }
    }

    > div {
      width: 100% !important;

      > div {
        width: 100% !important;
        height: auto !important;
        padding: 0 !important;
      }
    }

    input {
      width: 100% !important;
      background: rgba(248, 248, 248, 1) !important;
      border: 1px solid rgba(234, 234, 234, 1) !important;
      border-radius: 5px !important;
      height: 50px !important;
      font: {
        family: Inter !important;
        style: normal !important;
        weight: normal !important;
        size: 16px !important;
      }
      line-height: 20px !important;
      color: rgba($text-color, 1) !important;
      padding: {
        left: 20px !important;
        right: 20px !important;
        top: 22px !important;
        bottom: 8px !important;
      }
      outline: none !important;
      z-index: 1 !important;
      transition: border-color $defualt-animation-time $defualt-animation-ease,
        border-width $defualt-animation-time $defualt-animation-ease,
        box-shadow $defualt-animation-time $defualt-animation-ease;

      &:focus,
      &:hover {
        box-shadow: rgba(15, 119, 74, 1) 0px 0px 0px 1px inset;
        border-color: rgba(15, 119, 74, 1);
      }
    }
  }

  &__screen-checkbox {
    @include media("min", 591px) {
      row-gap: 15px;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
    border-top: 1px solid rgba(39, 39, 39, 0.1);
    margin-top: 30px;
    padding: {
      bottom: 10px;
      top: 32px;
    }
    max-width: 715px;
  }

  &__contact-form-container {
    padding: 12px 20px;
  }
}

.twopage-funnel-screen {
  padding: {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
}

.twopage-funnel-heading {
  line-height: normal;
  margin-top: 24px;
  // @include media("max", 767px) {
  //   font-size: 24px !important;
  //   margin-top: 0px;
  // }
}

.twopage-funnel-controls {
  @include media("max", 767px) {
    flex-direction: column;
    padding: 0px;

    button {
      width: 100%;
      margin-top: 16px;
      margin-left: 0px !important;
    }
  }
}

.twopage-text-container {
  @include media("max", 767px) {
    width: 100%;
  }
}

.twopage-funnel-text {
  @include media("max", 767px) {
    font-size: 14px !important;
  }
}
