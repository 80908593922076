.signin {
  @include media("min", 767px) {
    // margin-top: 100px;
    // padding-top: 40px;
    padding-bottom: 100px;
  }

  // margin-top: 64px;
  // padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-bottom: 50px;

  &__container {
    @extend %width-restrict;

    @include media("min", 935px) {
      grid-template-areas: "sidebar content .";
    }

    @include media("min", 773px) {
      grid-template-columns: 1fr minmax(auto, 625px) 1fr;
      grid-template-areas:
        "sidebar . ."
        ". content .";
    }

    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas:
      "sidebar"
      "content";
    row-gap: 25px;
  }

  &__sidebar {
    grid-area: sidebar;
  }

  &__back-button {
    @extend %text-button--arrow-left;

    font-weight: 600;
    opacity: 0.8;
  }

  &__content {
    grid-area: content;
  }
}
