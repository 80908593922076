.CheckBox {
  @extend %input-group;

  &.\--lead {
    label {
      font-weight: 600;
    }
  }

  &.\--help-text {
    label {
      @extend %input-group__help-text;

      margin: 0;
    }
  }

  &.\--inline {
    label {
      align-items: flex-start;
    }
  }

  &.\--inline-funnel {
    input[type="checkbox"] {
      border: 1px solid #585f69;
      background-color: #f5f5f5;

      &:hover {
        background-color: #747b85;
        border-color: #747b85;
      }

      &:checked {
        background-color: #747b85;
        border: 1px solid #747b85;

        &::before {
          position: absolute;
          display: block;
          content: "";
          width: 12px;
          height: 9px;
          background: {
            image: url("/static/checkbox-icon.svg");
            repeat: no-repeat;
            position: center;
          }
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    label {
      font-size: 12px;
    }
  }

  label {
    @extend %body;

    width: fit-content;
    display: flex;
    align-items: center;
    line-height: 19.36px;
    position: relative;
    pointer-events: initial;
    top: 0;
    left: 0;
    cursor: pointer;
    color: map-get($map: $colors, $key: text-black);
  }

  &__help-text {
    @extend %input-group__help-text;

    margin-left: 32px;
    margin-right: 0;
  }

  &__errors {
    @extend %input-group__errors;
  }
}
