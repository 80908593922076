.signin-button {
  margin-right: 8px;
  svg {
    path {
      fill: map-get($map: $colors, $key: "text-black");
    }
  }

  &.\--white {
    svg {
      path {
        fill: map-get($map: $colors, $key: "text-white");
      }
    }
  }
}
