.Select {
  position: relative;
  text-align: left;

  &__search {
    margin-bottom: 5px;
  }

  label {
    font-size: 16px;
    line-height: 20px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    color: rgba(map-get($map: $colors, $key: text-black), 0.6);

    &.\--active {
      display: hidden;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    appearance: none;
    background-color: map-get($map: $colors, $key: inputs-gray);
    border-radius: 5px;
    border: 1px solid rgba(234, 234, 234, 1);
    height: 50px;
    outline: none;
    transition: border-color map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease),
      border-width map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease),
      box-shadow map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease);
    width: 100%;
    resize: none;

    &:focus,
    &:hover {
      box-shadow: rgba(15, 119, 74, 1) 0px 0px 0px 1px inset;
      border-color: rgba(15, 119, 74, 1);
    }

    &:hover.\--invalid {
      box-shadow: rgba(163, 38, 0, 1) 0px 0px 0px 1px inset !important;
      border-color: rgba(163, 38, 0, 1) !important;
    }

    &.\--inline-funnel {
      background-color: #f5f5f5;
      height: 40px;
      font-size: 14px;
    }

    &.\--label-active {
      .Select__button {
        color: map-get($map: $colors, $key: text-black);
      }
    }

    &.\--valid {
      border-color: rgba(15, 119, 74, 1);
    }

    &.\--invalid {
      box-shadow: none !important;
      border-color: rgba(163, 38, 0, 1) !important;
    }

    &.\--active {
      border-color: rgba(126, 126, 126, 0.5);

      .Select {
        &__button {
          color: map-get($map: $colors, $key: text-black);

          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  &__button {
    @extend %remove-outline;

    @include media("min", 1440px) {
      padding: {
        left: 20px;
        right: 20px;
        top: 8px;
        bottom: 8px;
      }
    }

    padding: {
      left: 20px;
      right: 8px;
      top: 8px;
      bottom: 8px;
    }
    position: relative;
    appearance: none;
    cursor: pointer;
    display: inline-flex;
    width: 100%;
    color: rgba(map-get($map: $colors, $key: text-black), 0.6);
    font-size: 16px;
    line-height: 20px;
    transition: color map-get($map: $animation, $key: time)
      map-get($map: $animation, $key: ease);

    &.\--inline-funnel {
      font-size: 14px;
    }

    &::after {
      @include media("min", 1440px) {
        right: 20px;
      }
      position: absolute;
      right: 8px;
      content: "";
      mask-image: url("/static/dropdown-caret.svg");
      mask-position: center;
      mask-size: contain;
      height: 9px;
      width: 15px;
      background-color: map-get($map: $colors, $key: text-black);
      top: 14px;
      transition: transform map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease);
    }
  }

  &__dropdown {
    position: absolute;
    margin-top: 8px;
    width: 100%;
    display: none;
    background: #ffffff;
    border: 1px solid rgba(map-get($map: $colors, $key: text-black), 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 11;

    &.\--active {
      display: block;
    }

    > div > ul {
      overflow-y: auto;
      max-height: calc((39px * 4) + 15px);
      padding: {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      > li {
        list-style: none;
        padding: {
          left: 20px;
          right: 20px;
        }

        padding-top: calc(15px / 2);
        padding-bottom: calc(15px / 2);

        &:hover {
          background-color: rgba(248, 248, 248, 1);
        }

        &.\--active {
          background-color: rgba(248, 248, 248, 1);
        }
      }
    }
  }

  &__dropdown-button {
    @extend %remove-outline;

    appearance: none;
    background-color: transparent;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: map-get($map: $colors, $key: text-black);
    text-align: left;
    cursor: pointer;

    &.\--inline-funnel {
      font-size: 14px;
    }
  }

  &__help-text {
    @extend %input-group__help-text;
  }
}
