.progress-checklist {
  @include media("min", 1295px) {
    display: block;
  }
  display: none;
  counter-reset: marker;
  list-style: none;
  padding: 0;
  position: sticky;
  top: 0px;

  &__item-content {
    max-width: 259px;
    margin-left: 16px;
    font: {
      family: "Inter", sans-serif;
      size: 14px;
      weight: 400;
    }

    line-height: 16.94px;
    color: rgba(map-get($map: $colors, $key: text-black), 0.8);
  }

  &__item-heading {
    text-transform: uppercase;
    font: {
      family: "Inter", sans-serif;
      size: 14px;
      weight: 600;
    }
    line-height: 24px;
    color: map-get($map: $colors, $key: text-black);
    margin-bottom: 2px;
    transition: color map-get($map: $animation, $key: time)
      map-get($map: $animation, $key: ease);
  }

  &__item {
    position: relative;
    padding-left: 39px;
    text-align: left;
    appearance: none;
    padding-bottom: 24px;

    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      counter-increment: marker;
      position: absolute;
      height: 24px;
      width: 24px;
      left: 0;
      top: 0;
      content: counter(marker);
      border: 1px solid #e7e7e6;
      border-radius: 50%;
      font: {
        family: "Inter", sans-serif;
        size: 14px;
        weight: 600;
      }
      line-height: 24px;
      transition: color map-get($map: $animation, $key: time)
          map-get($map: $animation, $key: ease),
        border-color map-get($map: $animation, $key: time)
          map-get($map: $animation, $key: ease);
    }

    &::after {
      position: absolute;
      content: "";
      height: calc(100% - 24px - 12px);
      width: 1px;
      background-color: #e7e7e6;
      bottom: 6px;
      left: 12px;
    }

    &.\--active {
      &::before {
        border-color: map-get($map: $colors, $key: rea-orange);
        color: map-get($map: $colors, $key: rea-orange);
      }

      .progress-checklist__item-heading {
        color: map-get($map: $colors, $key: rea-orange);
      }
    }

    &.\--complete {
      &::before {
        background-color: map-get($map: $colors, $key: rea-orange);
        border-color: map-get($map: $colors, $key: rea-orange);
        mask-image: url("/static/checkmark-bullet.svg");
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: center;
        content: "";
      }
    }

    & + & {
      margin-top: 6px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
