%card {
  @include media("min", 549px) {
    padding: {
      bottom: 30px;
      left: 35px;
      right: 35px;
      top: 30px;
    }
  }

  background: map-get($map: $colors, $key: "text-white");
  box-shadow: 0px 0px 28px rgba(236, 236, 231, 0.6);
  border-radius: 5px;
  padding: {
    bottom: 15px;
    left: 15px;
    right: 15px;
    top: 15px;
  }
}
