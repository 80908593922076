.MessageAgentModal {
  @include media("min", 768px) {
    display: block;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  outline: none;

  &.\--large {
    .MessageAgentModal {
      &__body {
        max-width: 843px;
      }

      &__container {
        @include media("min", 1024px) {
          min-height: 500px;
          grid-template-columns: 1fr minmax(100px, 843px) 1fr;
        }
      }
    }
  }

  &__open {
    overflow: hidden;
  }

  &__body {
    @include media("min", 376px) {
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
    @include media("min", 768px) {
      margin-bottom: 100px;
      // height: auto;
    }
    &.\--agent-page {
      @include media("min", 768px) {
        margin-bottom: 70px;
      }
    }
    background-color: map-get($map: $colors, $key: text-white);
    margin: {
      left: auto;
      right: auto;
    }

    // min-height: 348px;
    width: 100%;
    height: fit-content;
    grid-area: body;
    position: relative;
    transition: max-width map-get($map: $animation, $key: ease)
      map-get($map: $animation, $key: time);
    // overflow-y: auto;
    // overflow-x: hidden;

    &.\--overflow {
      @include media("min", 768px) {
        overflow: initial;
      }
    }
  }

  &__close-confirm {
    @include animation-keyframes {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @include media("min", 768px) {
      padding-top: 0;
      padding-bottom: 0;
      align-items: center;
      height: initial;
    }
    animation: {
      timing-function: map-get($map: $animation, $key: ease);
      duration: map-get($map: $animation, $key: time);
      fill-mode: forwards;
    }
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    background-color: map-get($map: $colors, $key: text-white);
    border-radius: 5px;
    padding-top: 50px;
    padding-bottom: 50px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    height: fit-content;

    font: {
      family: "Inter", sans-serif;
      style: normal;
      weight: normal;
    }
  }

  &__close-confirm-inner {
    @include animation-keyframes {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
    animation: {
      timing-function: map-get($map: $animation, $key: ease);
      duration: map-get($map: $animation, $key: time);
      fill-mode: forwards;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__close-confirm-heading {
    font: {
      weight: bold;
      size: 24px;
    }
    line-height: 29px;
    color: rgba(26, 26, 26, 1);
    text-align: center;
    margin: {
      top: 15px;
      bottom: 15px;
    }
  }

  &__close-confirm-sub-heading {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 10px;
  }

  &__container {
    @include animation-keyframes {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }
    animation: {
      timing-function: map-get($map: $animation, $key: ease);
      duration: map-get($map: $animation, $key: time);
      fill-mode: forwards;
    }
    @include media("min", 768px) {
      padding-top: 130px;
      height: auto;
      width: auto;
    }
    @include media("min", 1024px) {
      grid-template-columns: 1fr minmax(100px, 625px) 1fr;
      grid-template-areas: ". body .";
    }
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "body";
    row-gap: 10px;
    outline: none;
    height: 95%;
    width: 100%;

    &.\--agent-page {
      @include media("min", 768px) {
        padding-top: 100px;
        height: auto;
        width: auto;
      }
    }
  }

  &__overlay {
    @include media("min", 768px) {
      align-items: flex-start;
    }
    background-color: rgba(107, 114, 128, 0.75);
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity map-get($map: $animation, $key: time)
      map-get($map: $animation, $key: ease);
    width: 100%;
    z-index: 10;
    overflow: auto;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }
}
