.Tel {
  width: 100%;
  position: relative;

  &.\--createProfileProfInfo {
    @media (min-width: 841px) {
      grid-column: 1 / span 2;
    }
    grid-column: 1;
  }

  &.\--fadeDownIn {
    animation-name: fadeDownIn;
    animation-timing-function: map-get($map: $animation, $key: ease);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
  }

  &__wrapper {
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid rgba(234, 234, 234, 1);
    border-radius: 5px;
    overflow: hidden;
    height: 50px;

    transition: border-color map-get($map: $colors, $key: time)
        map-get($map: $colors, $key: ease),
      box-shadow map-get($map: $colors, $key: time)
        map-get($map: $colors, $key: ease);

    &:hover,
    &.\--active {
      box-shadow: map-get($map: $colors, $key: inputs-success) 0px 0px 0px 1px;
      border-color: map-get($map: $colors, $key: inputs-success);
    }

    &.\--valid {
      border-color: map-get($map: $colors, $key: inputs-success);
    }

    &:hover {
      &.\--invalid {
        box-shadow: map-get($map: $colors, $key: inputs-error) 0px 0px 0px 1px !important;
        border-color: map-get($map: $colors, $key: inputs-error) !important;
      }
    }

    &.\--invalid {
      box-shadow: none !important;
      border-color: map-get($map: $colors, $key: inputs-error) !important;
    }
  }

  &__button {
    @extend %remove-outline;

    position: relative;
    min-width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(234, 234, 234, 1);
    font-size: 20px;
    padding-right: 17px;
    margin: 0;

    &::after {
      position: absolute;
      content: "";
      background-color: rgba(map-get($map: $colors, $key: text-black), 0.8);
      mask-image: url("/static/dropdown-caret--alt.svg");
      mask-repeat: no-repeat;
      mask-position: center;
      right: 15px;
      top: 21px;
      height: 6px;
      width: 10px;
      transition: transform map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease);
    }
    &.\--active {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__input-wrapper {
    width: 100%;
    position: relative;

    input {
      @extend %remove-outline;

      appearance: none;
      background-color: map-get($map: $colors, $key: inputs-gray);
      color: rgba(39, 39, 39, 1);
      font-size: 16px;
      line-height: 20px;
      padding: {
        left: 20px;
        right: 20px;
        top: 22px;
        bottom: 8px;
      }
      width: 100%;
    }

    label {
      font-size: 16px;
      left: 20px;
      line-height: 20px;
      pointer-events: none;
      position: absolute;
      top: 15px;
      transition: font-size 100ms map-get($map: $animation, $key: ease),
        line-height 100ms map-get($map: $animation, $key: ease),
        top 100ms map-get($map: $animation, $key: ease);
      width: 100%;
      color: rgba(map-get($map: $colors, $key: text-black), 0.6);

      &.\--active {
        font-size: 12px;
        line-height: 12px;
        top: 6px;
      }

      &.\--inline-funnel {
        top: 12px;
        font-size: 14px;
      }
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    display: none;
    margin-top: 9px;
    background-color: #fff;
    border: 1px solid rgba(map-get($map: $colors, $key: text-black), 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    z-index: 10;
    max-height: 250px;
    overflow-y: auto;

    &.\--active {
      display: block;
    }
  }

  &__search {
    position: sticky;
    top: 0;
    margin: 0;
    padding-left: 50px;
    padding-right: 21px;
    border-bottom: 1px solid rgba(map-get($map: $colors, $key: text-black), 0.1);
    background-color: #fff;
    z-index: 11;
    padding-top: 15px;
    padding-bottom: 15px;

    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 21px;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(125, 125, 125, 1);
      mask-image: url("/static/search-icon.svg");
      mask-size: contain;
    }
  }

  input {
    &.Tel__search-input {
      @extend %remove-outline;
      position: relative;
      padding: 0;
      margin: 0;
      background-color: transparent;
      appearance: none;
      z-index: 11;
    }
  }

  &__search-type-head {
    position: absolute;
    top: 15px;
    left: 50px;
    right: 0;
    bottom: 0;
    z-index: 5;
    opacity: 0.4;
  }

  &__dropdown-section {
    border-bottom: 1px solid rgba(map-get($map: $colors, $key: text-black), 0.1);
    padding: 0;
    padding-top: calc(15px / 2);
    padding-bottom: calc(15px / 2);
    button {
      display: grid;
      grid-template-columns: 20px 1fr;
      column-gap: 10px;
      padding-top: calc(15px / 2);
      padding-bottom: calc(15px / 2);
      padding-left: 21px;
      width: 100%;
      text-align: left;
      transition: background-color map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease);

      &:hover {
        background-color: rgba(248, 248, 248, 1);
      }

      &.\--active {
        .Tel__dropdown-label {
          position: relative;
          &::after {
            content: "";
            width: 13px;
            height: 10px;
            background-color: map-get($map: $colors, $key: rea-orange);
            mask-image: url("/static/checkbox-icon.svg");
            mask-size: contain;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    li {
      list-style: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
