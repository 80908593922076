.LogoutModal {
  @extend .MessageAgentModal;

  &__open {
    @extend .MessageAgentModal__open;
  }

  &__overlay {
    @extend .MessageAgentModal__overlay;
  }

  &__container {
    @extend .MessageAgentModal__container;
  }

  &__body {
    @extend .MessageAgentModal__body;
  }

  &__content {
    @extend .MessageAgentModal__close-confirm-inner;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__heading {
    @extend .MessageAgentModal__close-confirm-heading;
  }

  &__sub-heading {
    @extend .MessageAgentModal__close-confirm-sub-heading;
  }

  &__screen-controls {
    @extend .MessageAgentForm__screen-controls;
  }
}
