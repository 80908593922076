.signin-box {
  @extend %card;
  text-align: center;

  a {
    @extend %link;

    font-weight: 700;
  }

  .\--text-left {
    text-align: left;
  }

  .\--text-right {
    @include media("min", 549px) {
      text-align: right;
    }

    text-align: left;
  }

  &__heading {
    @extend %h3;

    margin-bottom: 15px;
  }

  &__text {
    @extend %body;

    text-align: center;
  }

  &__grid {
    @include media("min", 549px) {
      grid-template-columns: 1fr;
    }

    display: grid;
    grid-template-columns: 1fr;
    row-gap: 25px;
    column-gap: 25px;
    margin-top: 30px;

    .\--full-width {
      @include media("min", 549px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &__logo {
    max-width: 234px;
    margin: {
      left: auto;
      right: auto;
      bottom: 20px;
    }
    picture {
      @extend %picture;
    }
  }

  &__button {
    @extend %button;

    padding: {
      bottom: 10px;
      top: 10px;
    }
  }

  &__divider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: #e9e9e9;
      z-index: 1;
    }
  }

  &__divider-text {
    @extend %body;

    width: fit-content;
    background-color: map-get($map: $colors, $key: text-white);
    padding: {
      left: 11px;
      right: 11px;
    }
    z-index: 2;
  }

  &__footer {
    @extend %body;

    margin-top: 20px;
  }

  &__3rd-party {
    list-style: none;
    margin-top: 20px;
    padding: 0;
    display: flex;
    justify-content: center;

    li {
      & + li {
        margin-left: 20px;
      }
    }
  }

  &__3rd-party-button {
    appearance: none;
    cursor: pointer;
    border: 1px solid #94a3b8;
    height: 48px;
    width: 48px;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: border-color map-get($map: $animation, $key: time)
      map-get($map: $animation, $key: ease);

    svg {
      path {
        transition: fill map-get($map: $animation, $key: time)
          map-get($map: $animation, $key: ease);
      }
    }

    &:hover {
      border-color: map-get($map: $colors, $key: rea-orange);
      svg {
        path {
          fill: map-get($map: $colors, $key: rea-orange);
        }
      }
    }

    &:active {
      border-color: map-get($map: $colors, $key: reea-orange--active);
      svg {
        path {
          fill: map-get($map: $colors, $key: rea-orange--active);
        }
      }
    }
  }
}
