@use "sass:map";
@use "~/sass/vars";
@use "~/sass/atoms/typography/body";

.Toggle {
  &__container {
    position: relative;
    display: block;
    height: 100%;
    width: 44px;
    min-width: 44px;
    margin-right: 10px;
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(229, 231, 235, 1);
    border-radius: 40px;
    transition: background-color 100ms map.get(vars.$animation, ease);
    height: 24px;
    width: 44px;

    &::before {
      position: absolute;
      margin: auto 0;
      top: 0;
      bottom: 1px;
      left: 2px;
      content: "";
      height: 19px;
      width: 19px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid rgba(252, 221, 200, 1);
      transition: transform 100ms map.get(vars.$animation, ease);
    }
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;

    &:focus + .Toggle__slider {
      box-shadow: 0 0 1px rgba(234, 88, 12, 1);
    }

    &:checked + .Toggle__slider {
      background-color: rgba(234, 88, 12, 1);

      &::before {
        transform: translateX(19px);
      }
    }
  }

  label {
    @extend %body;
    text-align: left;
    line-height: 1.5;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
