@use 'sass:map';
@use '/styles/vars';

.CheckBoxLine {
  display: flex;
  align-items: flex-start;

  &__info_icon_container {
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    padding-bottom: 2px;
  }

  &__info_icon {
    width: 16px;
    max-width: none;
  }

  &__tooltip {
    max-width: 292px;
  }
}

.Toggle {
  &__label {
    font-size: 13px;
  }
}

.ClientTypeRadioButtons {
  &__header {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }

  &__input_container {
    display: flex;
    font-size: 14px;
    div {
      margin-right: 24px;
    }
  }
}

.ContactForm {
  &__cta-container {
    @media screen and (max-width: 1023px) {
      flex-direction: column-reverse;
    }
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
