%button {
  @extend %typography-button;
  @extend %remove-outline;

  @include media("min", 485px) {
    min-width: 134px;
  }
  appearance: none;
  align-items: center;
  background-color: map-get($colors, "rea-orange");
  border-radius: 5px;
  color: map-get($colors, "text-white");
  cursor: pointer;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  margin: {
    top: 20px;
    bottom: 16px;
  }
  min-width: fit-content;
  padding: {
    bottom: 15px;
    left: 30px;
    right: 30px;
    top: 14px;
  }
  text-align: center;
  transition: background-color map-get($animation, "time")
      map-get($animation, "ease"),
    color map-get($animation, "time") map-get($animation, "ease"),
    border-color map-get($animation, "time") map-get($animation, "ease");

  &:hover {
    background-color: map-get($map: $colors, $key: "rea-orange--hover");
  }

  &:active {
    background-color: map-get($map: $colors, $key: "rea-orange--active");
  }

  &:disabled {
    cursor: not-allowed;
    background-color: map-get($map: $colors, $key: "disabled");
  }
}

%button--secondary {
  @extend %button;

  background-color: map-get($map: $colors, $key: "rea-blue");
  font-weight: 600;
  padding: {
    bottom: 10px;
    top: 10px;
  }

  &:hover {
    background-color: map-get($map: $colors, $key: "rea-blue--hover");
  }

  &:active {
    background-color: map-get($map: $colors, $key: "rea-blue--hover");
  }

  &:disabled {
    background-color: map-get($map: $colors, $key: "disabled");
  }
}

%button--outline {
  @extend %button;

  color: map-get($map: $colors, $key: "rea-orange");
  background-color: map-get($map: $colors, $key: "text-white");
  border: {
    color: map-get($map: $colors, $key: "rea-orange");
    style: solid;
    width: 1px;
  }
  font-weight: 600;
  padding: {
    bottom: 10px;
    top: 10px;
  }

  &:hover {
    color: map-get($map: $colors, $key: "rea-orange--hover");
    border-color: map-get($map: $colors, $key: "rea-orange--hover");
    background-color: map-get($map: $colors, $key: "text-white");
  }

  &:active {
    color: map-get($map: $colors, $key: "rea-orange--active");
    border-color: map-get($map: $colors, $key: "rea-orange--active");
    background-color: map-get($map: $colors, $key: "text-white");
  }

  &:disabled {
    color: map-get($map: $colors, $key: "disabled");
    border-color: map-get($map: $colors, $key: "disabled");
    background-color: map-get($map: $colors, $key: "text-white");
  }
}

%button--secondary--outline {
  @extend %button--secondary;
  @extend %button--outline;

  color: map-get($map: $colors, $key: "rea-blue");
  border-color: #94a3b8;
  background-color: map-get($map: $colors, $key: "text-white");

  &:hover {
    color: map-get($map: $colors, $key: "rea-blue--hover");
    border-color: map-get($map: $colors, $key: "rea-blue--hover");
  }

  &:active {
    color: map-get($map: $colors, $key: "rea-blue--hover");
    border-color: map-get($map: $colors, $key: "rea-blue--hover");
  }

  &:disabled {
    color: map-get($map: $colors, $key: "disabled");
    border-color: map-get($map: $colors, $key: "disabled");
  }
}

%text-button {
  @extend %typography-button;
  @extend %remove-outline;

  color: map-get($map: $colors, $key: "rea-blue");
  display: inline;
  font-weight: 700;
  transition: color map-get($animation, "time") map-get($animation, "ease");
  cursor: pointer;

  &:hover {
    color: map-get($map: $colors, $key: "rea-blue--hover");
  }

  &:active {
    color: map-get($map: $colors, $key: "rea-blue--hover");
  }

  &:disabled {
    color: map-get($map: $colors, $key: "disabled");
  }
}

%text-button--arrow-left {
  @extend %text-button;

  position: relative;
  padding-left: calc(8px + 5.91px);
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 5.91px;
    height: 9.85px;
    background-color: map-get($map: $colors, $key: "rea-blue");
    mask-image: url("/static/back-button.svg");
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &:hover {
    &::after {
      background-color: map-get($map: $colors, $key: "rea-blue--hover");
    }
  }

  &:active {
    &::after {
      background-color: map-get($map: $colors, $key: "rea-blue--hover");
    }
  }

  &:disabled {
    &::after {
      background-color: map-get($map: $colors, $key: "disabled");
    }
  }
}

%text-button--arrow-right {
  @extend %text-button;

  position: relative;
  padding-right: calc(8px + 5.91px);
  &::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 5.91px;
    height: 9.85px;
    background-color: map-get($map: $colors, $key: "rea-blue");
    mask-image: url("/static/right-arrow.svg");
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &:hover {
    &::after {
      background-color: map-get($map: $colors, $key: "rea-blue--hover");
    }
  }

  &:active {
    &::after {
      background-color: map-get($map: $colors, $key: "rea-blue--hover");
    }
  }

  &:disabled {
    &::after {
      background-color: map-get($map: $colors, $key: "disabled");
    }
  }
}

%link {
  color: map-get($map: $colors, $key: rea-orange);
  transition: color map-get($map: $animation, $key: time)
    map-get($map: $animation, $key: ease);

  &:hover {
    color: map-get($map: $colors, $key: rea-orange--hover);
  }

  &:active {
    color: map-get($map: $colors, $key: rea-orange--active);
  }
}
