%remove-outline {
  outline: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

%width-restrict {
  margin: {
    left: auto;
    right: auto;
  }
  max-width: map-get($grid, "normal-width");
  padding: {
    left: map-get($grid, "gutter");
    right: map-get($grid, "gutter");
  }
}
