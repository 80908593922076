%input-group {
  position: relative;
  text-align: left;

  label {
    font-size: 16px;
    left: 20px;
    line-height: 20px;
    pointer-events: none;
    position: absolute;
    top: 15px;
    transition: font-size 100ms map-get($map: $animation, $key: ease),
      line-height 100ms map-get($map: $animation, $key: ease),
      top 100ms map-get($map: $animation, $key: ease);
    width: 100%;
    color: rgba(map-get($map: $colors, $key: text-black), 0.6);

    &.\--active {
      font-size: 12px;
      line-height: 12px;
      top: 6px;
    }

    &.\--inline-funnel {
      top: 12px;
      font-size: 14px;
    }

    &.\--inline-funnel-active {
      font-size: 10px;
      line-height: 10px;
      top: 4px;
      padding-right: 20px;
    }
  }

  input:not([type="checkbox"]):not([type="radio"]),
  textarea {
    appearance: none;
    background-color: map-get($map: $colors, $key: inputs-gray);
    border-radius: 5px;
    border: 1px solid rgba(234, 234, 234, 1);
    color: rgba(39, 39, 39, 1);
    font-size: 16px;
    height: 50px;
    line-height: 20px;
    outline: none;
    padding: {
      left: 20px;
      right: 20px;
      top: 22px;
      bottom: 8px;
    }
    transition: border-color map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease),
      border-width map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease),
      box-shadow map-get($map: $colors, $key: time)
        map-get($map: $animation, $key: ease);
    width: 100%;
    resize: none;

    &:focus,
    &:hover {
      box-shadow: map-get($map: $colors, $key: inputs-success) 0px 0px 0px 1px
        inset;
      border-color: map-get($map: $colors, $key: inputs-success);
    }

    &.\--valid {
      border-color: map-get($map: $colors, $key: inputs-success);
    }

    &:hover.\--invalid {
      box-shadow: map-get($map: $colors, $key: inputs-error) 0px 0px 0px 1px
        inset !important;
      border-color: map-get($map: $colors, $key: inputs-error) !important;
    }

    &.\--invalid {
      box-shadow: none !important;
      border-color: map-get($map: $colors, $key: inputs-error) !important;
    }
  }

  textarea {
    height: auto;
  }

  input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: map-get($map: $colors, $key: inputs-gray);
    border: 1px solid #eaeaea;
    border-radius: 2px;
    margin-right: 12px;
    position: relative;
    transition: background-color map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease),
      border-color map-get($map: $animation, $key: time)
        map-get($map: $animation, $key: ease);

    &:after {
      position: absolute;
      content: "";
      mask-image: url("/static/checkbox-icon.svg");
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: map-get($map: $colors, $key: text-white);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 9px;
      opacity: 0;
      transition: opacity map-get($map: $colors, $key: time)
        map-get($map: $colors, $key: ease);
    }

    &:hover {
      background-color: map-get($map: $colors, $key: rea-orange--hover);
      border-color: map-get($map: $colors, $key: rea-orange--hover);
    }

    &:checked {
      background-color: map-get($map: $colors, $key: rea-orange);
      border-color: map-get($map: $colors, $key: rea-orange);

      &:after {
        opacity: 1;
      }
    }
  }

  &__help-text {
    font: {
      family: "Inter", sans-serif;
      size: 14px;
      weight: 400;
    }
    line-height: 16.94px;
    color: rgba(map-get($map: $colors, $key: text-black), 0.8);
    margin-top: 8px;
    margin-left: 21px;
    margin-right: 21px;
  }

  &__errors {
    @include animation-keyframes {
      from {
        opacity: 0;
        transform: translateY(-38px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    animation: {
      timing-function: map-get($map: $animation, $key: time);
      duration: map-get($map: $animation, $key: ease);
      fill-mode: forwards;
    }
    padding: {
      top: 8px;
      left: 21px;
      right: 21px;
    }
    font-size: 12px;
    color: map-get($map: $colors, $key: inputs-error);
    list-style: none;

    li {
      @include animation-keyframes {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      animation: {
        timing-function: map-get($map: $animation, $key: time);
        duration: map-get($map: $animation, $key: ease);
        fill-mode: forwards;
      }
      display: flex;

      &::before {
        position: absolute;
        left: 0;
        content: "";
        height: 16px;
        width: 16px;
        background-image: url("/static/error-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
