// Mixin for shadow button with block sliding up
@mixin btn-border-slide($color, $hoverColor, $borderWidth, $time) {
  $easing: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.5em 2em;
  position: relative;

  &:before,
  &:after {
    background-color: $color;
    content: "";
    height: $borderWidth;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:hover {
    & > * {
      & > * {
        &:before,
        &:after {
          transform: translate3d(0, 0, 0);
        }

        & > * {
          &:before,
          &:after {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  & > * {
    &:before,
    &:after {
      background-color: $color;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: $borderWidth;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    & > * {
      &:before,
      &:after {
        background-color: $hoverColor;
        content: "";
        height: $borderWidth;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 9;
      }

      &:before {
        top: 0;
        transform: translate3d(-105%, 0, 0);
        transition: transform $time $easing;
      }

      &:after {
        bottom: 0;
        transform: translate3d(105%, 0, 0);
        transition: transform $time $easing;
      }

      & > * {
        &:before,
        &:after {
          background-color: $hoverColor;
          content: "";
          height: 100%;
          position: absolute;
          top: 0;
          width: $borderWidth;
          z-index: 9;
        }

        &:before {
          left: 0;
          transform: translate3d(0, 105%, 0);
          transition: transform $time $easing;
        }

        &:after {
          right: 0;
          transform: translate3d(0, -105%, 0);
          transition: transform $time $easing;
        }
      }
    }
  }
}

// Generates either a min width, max-width, range, or retina media query
//
// $media-query - One of four keywords: "retina", "min", "max", "minmax". Depending on the word you will get that type of media query output.
// $res       - The size for the breakpoint. Oftern this references a predefined variable. You should probably not have actual values in this field.
// $res2        - Same as $res and only used for minmax media queries to get a range. $res is starting point and $res2 is ending point of the range.
//
// Compatible in IE9+, Everything else.
@mixin media($media-query, $res: 0, $res2: 0) {
  @if $media-query == retina {
    //sass-lint:disable-block no-vendor-prefixes
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2 / 1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      @content;
    }
  }

  @if $media-query == min {
    @media screen and (min-width: $res) {
      @content;
    }
  }
  @if $media-query == max {
    @media screen and (max-width: $res) {
      @content;
    }
  }
}

@mixin animation-keyframes {
  $animation-number: 0 !default !global;
  $animation-number: $animation-number + 1 !global;
  $name: unquote("animation-#{$animation-number}");
  animation-name: $name;
  @at-root {
    @keyframes #{$name} {
      @content;
    }
  }
}
