.ProgressBar {
  background-color: rgba(234, 234, 234, 1);
  height: 12px;
  width: 100%;

  &__inner {
    background-color: map-get($map: $colors, $key: rea-orange);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    display: block;
    height: 100%;
    max-width: 0;
    transition: max-width map-get($map: $animation, $key: time)
      map-get($map: $animation, $key: ease);
    width: 100%;

    &.\--full {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
