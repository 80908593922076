/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.show-loading-animation {
  width: 100%;
}

.react-shape,
.round-shape,
.text-row {
  animation: pulse 1.5s infinite;
}

.agent-search-pagination .previous {
  margin-right: auto;
}

.agent-search-pagination .next {
  margin-left: auto;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

p {
  margin: 0px;
}

.react-tiny-popover-container {
  z-index: 12;
}

::-webkit-scrollbar {
  display: "none";
}

.react-tel-input {
  margin-bottom: 0px;
}

.react-tel-input .form-control {
  height: 60px !important;
  font-size: 16px !important;
  width: 100% !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}

.react-tel-input .selected-flag:hover {
  background: transparent;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
* BURGER MENU
*/

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: rgba(51, 102, 255, 0.7);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgba(51, 102, 255, 0.7);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: -60px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100vh;
  width: 100%;
  margin-top: -72;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em;
  outline: none;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/**
* PAGINATION
*/

li.disabled {
  opacity: 0;
}

/**
* RECHARTS
*/
.recharts-surface {
  overflow: visible;
}

/**
* FONTS
*/

/* @font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Bold.eot");
  src: url("/fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Inter-Bold.woff2") format("woff2"),
    url("/fonts/Inter-Bold.woff") format("woff"),
    url("/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Medium.eot");
  src: url("/fonts/Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Inter-Medium.woff2") format("woff2"),
    url("/fonts/Inter-Medium.woff") format("woff"),
    url("/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-ExtraBold.eot");
  src: url("/fonts/Inter-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Inter-ExtraBold.woff2") format("woff2"),
    url("/fonts/Inter-ExtraBold.woff") format("woff"),
    url("/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-Regular.eot");
  src: url("/fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Inter-Regular.woff2") format("woff2"),
    url("/fonts/Inter-Regular.woff") format("woff"),
    url("/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter-SemiBold.eot");
  src: url("/fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("/fonts/Inter-SemiBold.woff") format("woff"),
    url("/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */
