.Input {
  @extend %input-group;

  &__help-text {
    @extend %input-group__help-text;
  }

  &__errors {
    @extend %input-group__errors;
  }

  &__password-toggle {
    @extend %remove-outline;
    position: absolute;
    display: block;
    right: 21.18px;
    top: 14.39px;
    text-indent: -999%;
    overflow: hidden;
    appearance: none;
    height: 21.21px;
    width: 21.64px;
    mask-image: url("/static/password-show-icon.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: rgba(map-get($map: $colors, $key: text-black), 0.8);
    transition: background-color map-get($map: $animation, $key: time)
      map-get($map: $animation, $key: ease);

    &:hover {
      background-color: rgba(map-get($map: $colors, $key: text-black), 1);
    }

    &.\--active {
      mask-image: url("/static/password-hide-icon.svg");
    }
  }
}
