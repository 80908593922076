.alert {
  text-align: left;
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 2px;
  padding: {
    left: 20px;
    right: 20px;
    top: 10px;
    bottom: 10px;
  }
  border-radius: 5px;
  background-color: map-get($map: $colors, $key: inputs-gray);
  font-size: 16px;

  strong {
    display: block;
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
    font-weight: 600;
  }

  &.\--error {
    background-color: lighten(
      $color: map-get($map: $colors, $key: inputs-error),
      $amount: 65%
    );
    border-color: map-get($map: $colors, $key: inputs-error);
    color: map-get($map: $colors, $key: inputs-error);
  }

  &.\--fadeDownIn {
    animation-name: fadeDownIn;
    animation-timing-function: map-get($map: $animation, $key: ease);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}
