.TextArea {
  @extend %input-group;

  label {
    padding-right: 40px;
  }

  &__help-text {
    @extend %input-group__help-text;
  }

  &__errors {
    @extend %input-group__errors;
  }
}
