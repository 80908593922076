.MessageAgentButton {
  @extend %button;

  padding: {
    bottom: 10px;
    left: 20px;
    right: 20px;
    top: 10px;
  }

  &.\--secondary {
    @extend %button--secondary;

    padding: {
      bottom: 10px;
      left: 20px;
      right: 20px;
      top: 10px;
    }
  }

  &.\--right {
    @include media("min", 1024px) {
      margin-right: 0;
    }

    margin: {
      bottom: 0;
      left: auto;
      right: auto;
      top: 0;
    }
  }

  &.\--full-width {
    width: 100%;
  }
}

.blue {
  background: blue;
}
