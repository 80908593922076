.TwoFA {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;

  &.\--center {
    justify-content: center;
  }

  &__wrapper {
    max-width: 270px;
  }

  &__grid {
    width: 100%;
    max-width: 270px;
    display: grid;
    column-gap: 6px;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  }

  &__input {
    @extend %input-group;

    input {
      width: 40px !important;
      height: 48px !important;
      padding: 8px !important;
      text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield !important;
    }
  }

  &__help-text {
    @extend %input-group__help-text;

    margin-left: 0;
    margin-right: 0;
  }

  &__errors {
    @extend %input-group__errors;
  }
}
