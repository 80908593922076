%typography-body,
%body {
  @include media("min", map-get($breakpoints, "sm")) {
    font-size: 16px;
  }

  color: map-get($colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 14px;
    weight: 400;
  }
  line-height: 20px;

  &.\--large {
    @include media("min", map-get($breakpoints, "sm")) {
      font-size: 18px;
      line-height: 28px;
    }

    font-size: 16px;
    line-height: 22px;
  }

  &.\--small,
  small {
    @include media("min", map-get($breakpoints, "sm")) {
      font-size: 14px;
      line-height: 16.94px;
    }

    font-size: 12px;
    line-height: 14.52px;
  }
}

%typography-button {
  @include media("min", map-get($breakpoints, "sm")) {
    font-size: 16px;
    line-height: 28px;
  }

  color: map-get($colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 14px;
    weight: 600;
  }
  line-height: 28px;

  &.\--large {
    @include media("min", map-get($breakpoints, "sm")) {
      font-size: 18px;
      line-height: 21.78px;
    }

    font-size: 16px;
    line-height: 19.36px;
  }

  &.\--small,
  small {
    @include media("min", map-get($breakpoints, "sm")) {
      font-size: 14px;
      line-height: 24px;
    }

    font-size: 14px;
    line-height: 28px;
  }
}

%typography-subheader,
%subheader {
  @include media("min", map-get($breakpoints, "sm")) {
    font-size: 16px;
    line-height: 20px;
  }

  color: map-get($colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 14px;
    weight: 500;
  }
  line-height: 18px;
  text-transform: uppercase;

  &.\--small,
  small {
    @include media("min", map-get($breakpoints, "sm")) {
      font-size: 12px;
      line-height: 14.52px;
    }

    font-size: 12px;
    line-height: 20px;
  }
}

%typography-label,
%label {
  color: map-get($colors, "text-black");
  font: {
    family: "Inter", sans-serif;
    size: 10px;
    weight: 400;
  }
  line-height: 12px;
}
