@use 'sass:map';
@use '/styles/vars';

.PriceRange {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  margin: {
    left: auto;
    right: auto;
  }
  max-width: 619px;
  margin-top: 35px;
  width: 100%;

  &__container {
    position: relative;
    width: 100%;

    &:hover {
      .PriceRange__track {
        height: 8px;
      }
    }
  }

  &__track {
    position: absolute;
    display: block;
    height: 5px;
    width: calc(100% - 20px);
    margin: 0 auto;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    transition: height map.get($map: vars.$animation, $key: time)
      map.get($map: vars.$animation, $key: ease);
    border-radius: 5px;
  }

  input[type="range"] {
    position: relative;
    appearance: none;
    background-color: transparent;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    cursor: pointer;
    z-index: 4;

    &:focus {
      outline: none;
    }

    &::-moz-range-thumb {
      background: linear-gradient(
        0deg,
        map.get($map: vars.$colors, $key: rea-orange),
        map.get($map: vars.$colors, $key: rea-orange)
      );
      border-radius: 50%;
      border: 2px solid map.get($map: vars.$colors, $key: rea-orange);
      cursor: pointer;
      height: 20px;
      width: 20px;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background: linear-gradient(
        0deg,
        map.get($map: vars.$colors, $key: rea-orange),
        map.get($map: vars.$colors, $key: rea-orange)
      );
      border-radius: 50%;
      border: 2px solid map.get($map: vars.$colors, $key: rea-orange);
      cursor: pointer;
      height: 20px;
      width: 20px;
    }

    &::-ms-thumb {
      background: linear-gradient(
        0deg,
        map.get($map: vars.$colors, $key: rea-orange),
        map.get($map: vars.$colors, $key: rea-orange)
      );
      border-radius: 50%;
      border: 2px solid map.get($map: vars.$colors, $key: rea-orange);
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }

  &__labels {
    color: rgba(map.get($map: vars.$colors, $key: text-black), 0.8);
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 20px;
    margin: {
      top: 2px;
    }
    width: 100%;
  }

  &__heading {
    color: map.get($map: vars.$colors, $key: text-black);
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    width: 250px;
    text-align: center;
  }

  &__heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: {
      bottom: 10px;
    }
  }

  &__control {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 24px;
    line-height: 1;
    box-shadow: rgba(map.get($map: vars.$colors, $key: rea-blue), 0.08) 0px 0px
      10px;
  }
}
